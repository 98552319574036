
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import type { KioskModel } from "@common/models/kiosk";
	import type { OutletKioskModel } from "@common/models/merchant";

	@Component
	export default class extends Vue {

		@Prop(String) public id!: string;

		@Inject() public readonly confirm!: ConfirmMethod;
		@Inject() public readonly alert!: AlertMethod;

		protected data: OutletKioskModel | null = null;
		protected selected: boolean[] = [];

		mounted(): void {
			this.load();
		}

		protected name(k: KioskModel): string {
			return k.Name || "待命名";
		}

		protected toggle(i: number): void {
			Vue.set(this.selected, i, !this.selected[i]);
		}

		private async load(): Promise<void> {
			try {
				this.data = await post<OutletKioskModel>("/api/Merchant/Accept", { Id: this.id });
				for(let i = 0; i < this.data.Kiosks.length; i++) Vue.set(this.selected, i, false);
			} catch(e) {
				if(e instanceof Error) await this.alert(e.message, undefined, "error");
				this.$router.replace('/');
			}
		}

		protected async match(): Promise<void> {
			let ids: string[] = [];
			for(let i = 0; i < this.data!.Kiosks.length; i++) {
				if(this.selected[i]) ids.push(this.data!.Kiosks[i].Id);
			}

			try {
				await post<boolean>("/api/Merchant/Match", {
					OutletId: this.data!.Id,
					KioskId: ids,
				});
				await this.alert("配對成功", undefined, "success", "前往營收管理");
				this.$router.push("/report");
			} catch(e) {
				let msg = "配對失敗";
				if(e instanceof Error) msg += "：" + e.message;
				await this.alert(msg, undefined, "error", "回上一頁");
				this.$router.back();
			}
		}
	}
