
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";
	import clone from "clone";

	import Device from "../components/Device.vue";
	import Rename from "./Rename.vue";

	import type { OutletKioskModel } from "@common/models/merchant";
	import type { MerchantKioskDetailModel } from "@common/models/kiosk";

	@Component({ components: { Rename, Device } })
	export default class DeviceIndex extends Vue {
		$refs!: {
			rename: Rename;
		};


		@Prop(String) public id!: string;

		@Inject() public readonly alert!: AlertMethod;

		protected data: OutletKioskModel | null = null;
		protected kiosk: MerchantKioskDetailModel | null = null;

		protected get title(): string {
			return this.data ? this.data.Name : "";
		}

		mounted(): void {
			this.load();
		}

		private async load(): Promise<void> {
			this.data = null;
			this.data = await post<OutletKioskModel>("/api/Merchant/OutletDevice", { Id: this.id });
		}

		protected async edit(d: MerchantKioskDetailModel): Promise<void> {
			this.kiosk = clone(d);
			if(await this.$refs.rename.show("")) {
				let Name = d.Terminal!.Name = this.kiosk!.Terminal!.Name;
				await post<boolean>("/api/Merchant/RenameTerminal", { Id: this.kiosk!.Terminal!.Id, Name });
			}
		}
	}
