
	import { Component, Inject, Vue } from "vue-property-decorator";

	import type { CityOption, OutletModel, MerchantApplyModel } from "@common/models/merchant";

	@Component
	export default class extends Vue {

		@Inject() public readonly alert!: AlertMethod;

		protected options: CityOption[] | null = null;
		protected city: number | null = null;

		protected merchant: MerchantApplyModel | null = null;
		protected data: OutletModel = {
			MerchantId: '',
			Name: '',
			ShortName: '',
			Phone: '',
			Address: '',
			TownId: '',
			ContractDate: undefined,
			SellDate: undefined,
			OpenDate: undefined,
			TerminalCount: undefined,
			BusinessHour: '',
			Website: '',
			Remark: '',
		};

		mounted(): void {
			this.load();
		}

		// protected copy(): void {
		// 	this.data.Name = this.merchant!.BrandName;
		// 	this.data.Phone = this.merchant!.LiaisonContact.Phone!;
		// }

		private async load(): Promise<void> {
			this.merchant = await post<MerchantApplyModel>("/api/Merchant/Edit");
			this.data.MerchantId = this.merchant.Id;
			this.options = await post<CityOption[]>("/api/Merchant/Town");
		}

		protected async send(): Promise<void> {
			await post<boolean>('/api/Merchant/EditOutlet', { ...this.data });
			this.$router.back();
		}
	}
