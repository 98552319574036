
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import type { CityOption, OutletModel, MerchantApplyModel } from "@common/models/merchant";

	@Component
	export default class extends Vue {

		@Prop() public id!: string;

		@Inject() public readonly alert!: AlertMethod;

		protected options: CityOption[] | null = null;
		protected city: number | null = null;

		protected merchant: MerchantApplyModel | null = null;
		protected data: OutletModel | null = null;

		mounted(): void {
			this.load();
			// console.log(this.$router);
		}

		// protected copy(): void {
		// 	this.data!.Name = this.merchant!.BrandName;
		// 	this.data!.Phone = this.merchant!.LiaisonContact.Phone!;
		// }

		private async load(): Promise<void> {
			await Promise.all([
				post<OutletModel>("/api/Merchant/GetOutlet", { Id: this.id }).then(v => this.data = v),
				post<MerchantApplyModel>("/api/Merchant/Edit").then(v => this.merchant = v),
				post<CityOption[]>("/api/Merchant/Town").then(v => this.options = v),
			]);
			let city = this.options?.findIndex(c => c.Towns.some(t => t.Id == this.data?.TownId)) ?? -1;
			if(city >= 0) this.city = city;
		}

		protected async send(): Promise<void> {
			await post<boolean>('/api/Merchant/EditOutlet', { ...this.data });
			this.$router.back();
		}
	}
