
	import { Component, Prop } from 'vue-property-decorator';
	import Dialog from '@common/components/dialog/dialog';

	import type { MerchantKioskDetailModel } from '@common/models/kiosk';

	@Component
	export default class Rename extends Dialog<boolean> {
		@Prop() public kiosk!: MerchantKioskDetailModel | null;

		protected value: boolean = false;
	}
