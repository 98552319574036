
	import { Component, Inject, Vue } from "vue-property-decorator";

	import Outlet from "./components/Outlet.vue";
	import type { LeapAppMerchantModel } from "@common/models/merchant";

	@Component({
		components: { Outlet },
	})
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		protected data: LeapAppMerchantModel | null = null;

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = null;
			this.data = await post<LeapAppMerchantModel>("/api/Merchant");
		}

		protected detail(): void {
			this.$router.push("/merchant/edit");
		}
	}
